<template>
  <div class="">
    <template v-if="detailsLoading">
      <v-row style="padding: 80px 0">
        <v-col cols="12" class="text-center">
          <v-progress-circular :size="70" :width="4" color="gray" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </template>

    <RegistrationHeader
      icon="mdi-car"
      :title="app.yearMakeModel"
      :showSubtitle="false"
    ></RegistrationHeader>

    <template v-if="!detailsLoading">
      <v-card class="mx-auto mb-10" color="cardBackground">
        <div class="d-sm-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="headline">Application</v-card-title>
            <v-card-subtitle>Created on {{ app.DateCreated | date }}</v-card-subtitle>
          </div>
          <div>
            <!-- <div class="title ma-5">{{ app.Status }}</div> -->
          </div>
        </div>
        <v-card-text>
          <div>
            <v-btn
              to="application"
              elevation="0"
              class="d-flex d-sm-inline-flex mr-0 mr-sm-4 mb-4 mb-sm-0"
              color="success"
              :outlined="app.Status === this.appIncompleteStatus ? false : true"
            >
              <v-icon class="mr-2">{{ app.Status === this.appIncompleteStatus ? 'mdi-text-box-outline' : 'mdi-check' }}</v-icon>
              {{ app.Status === this.appIncompleteStatus ? 'Finish My Application' : 'View My Application' }}
            </v-btn>

            <v-btn
              to="application-next-steps"
              elevation="0"
              class="d-flex d-sm-inline-flex"
              color="success"
              :outlined="app.Status !== this.appIncompleteStatus && !app.RegistrationAmount ? false : true"
              v-if="app.Status !== this.appIncompleteStatus"
            >
              <v-icon class="mr-2">mdi-step-forward</v-icon>
              {{ app.Status !== this.appIncompleteStatus && !app.RegistrationAmount ? 'See My Next Steps' : 'Next Steps' }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mx-auto mb-10" color="cardBackground">
        <div>
          <v-card-title class="headline">Cost Breakdown</v-card-title>
          <v-card-subtitle>After your application has been processed, see the cost breakdown here</v-card-subtitle>
        </div>
        <v-card-text>
          <div class="text-h6" v-if="!app.RegistrationAmount">This section will become active when your cost has been finalized</div>

          <div>
            <v-btn
              to="cost-breakdown"
              elevation="0"
              class="d-flex d-sm-inline-flex mr-0 mr-sm-4 mb-4 mb-sm-0"
              color="success"
              :outlined="app.LastUpdatedCostBreakdown === notUpdatedDate ? false : true"
              v-if="!!app.RegistrationAmount"
            >
              <v-icon class="mr-2">{{ app.LastUpdatedCostBreakdown === notUpdatedDate ? 'mdi-currency-usd' : 'mdi-check' }}</v-icon>
              {{ app.LastUpdatedCostBreakdown === notUpdatedDate ? 'View My Cost Breakdown' : 'View My Cost Breakdown' }}
            </v-btn>

            <v-btn
              to="payment-details"
              elevation="0"
              class="d-flex d-sm-inline-flex"
              color="success"
              :outlined="app.LastUpdatedPaymentMethod === notUpdatedDate ? false : true"
              v-if="app.LastUpdatedCostBreakdown !== notUpdatedDate"
            >
              <v-icon class="mr-2">{{ app.LastUpdatedPaymentMethod === notUpdatedDate ? 'mdi-currency-usd' : 'mdi-check' }}</v-icon>
              {{ app.LastUpdatedPaymentMethod === notUpdatedDate ? 'Choose A Payment Method' : 'View Selected Payment Method' }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mx-auto" color="cardBackground">
        <div>
          <v-card-title class="headline">Title Transfered</v-card-title>
          <v-card-subtitle>Get your tracking number and reciept here</v-card-subtitle>
        </div>
        <v-card-text>
          <div class="text-h6" v-if="!app.ClientTrackingNumber">This section will become active when your plates are in the mail</div>

          <v-btn
            elevation="0"
            class="d-flex d-sm-inline-flex mr-0 mr-sm-4 mb-4 mb-sm-0 grey mainBlack--text"
            :href="app.fedExTrackingUrl"
            target="_blank"
            v-if="app.ClientTrackingNumber"
          >
            <v-icon class="mr-2">mdi-open-in-new</v-icon>
            View your FedEx tracking
          </v-btn>

          <v-btn
            elevation="0"
            class="d-flex d-sm-inline-flex grey mainBlack--text"
            :href="app.receiptDownloadUrl"
            target="_blank"
            v-if="app.ClientTrackingNumber"
          >
            <v-icon class="mr-2">mdi-download-outline</v-icon>
            Download your receipt
          </v-btn>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '../../../store';

export default {
  components: {
    // AppButton: () => import('@/components/app/AppButton'),
    RegistrationHeader: () => import('@/components/app/RegistrationHeader'),
  },
  beforeRouteEnter(to, from, next) {
    const regId = to.params.regId;
    const activeId = store.state.registration.app.RegistrationID;
    if (regId !== activeId) {
      store.dispatch('billOfSale/clearBos');
      store.dispatch('registration/setActiveRecord', regId).then(() => {
        // store.dispatch("registration/getRegistrationProgress", regId);
        next();
      });
    } else {
      // console.log('state already set');
      next();
    }
  },
  created() {
    store.dispatch('registration/slowPoller', {});
  },
  destroyed() {
    store.dispatch('registration/stopSlowPoller');
  },
  computed: {
    app() {
      return this.$store.state.registration.app;
    },
  },
  data: () => ({
    detailsLoading: false,
    notUpdatedDate: '1999-01-01T00:00:00.000Z',
    appIncompleteStatus: 'Draft',
    fedExTrackingUrl: null,
    toggle_exclusive: false,
  }),
  methods: {},
};
</script>

<style scoped>
.step-row {
  cursor: pointer;
  background: white;
}
.step-row:hover {
  background: #f1f1f1;
}
.app-btn {
  box-shadow: 0 0;
  border-radius: 25px 0 0 25px;
  height: 50px;
}
.next-btn {
  box-shadow: 0 0;
  border-radius: 0 25px 25px 0;
  height: 50px;
}
</style>
